import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from '../../styles/media';
import Container from '../Grid/Container';
import { Title } from '../common/styles';
import theme from '../../theme';
import useMobile from '../../utils/useMobile';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const Wrapper = styled.div`
  padding-top: 87px;
  padding-bottom: 127px;

  ${media.tablet`
    padding-top: 71px;
    padding-bottom: 83px;
  `};
`;

const StyledContainer = styled.div`
  margin-left: ${({ marginLeft }) => marginLeft || -100}px;
  margin-bottom: 40px;

  ${media.tablet`
    margion-bottom: 50px;
  `};
`;

const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  width: 100%;
  align-items: center;
  overflow: hidden;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform: translateX(0);
`;

const ImageWrapper = styled.a`
  min-width: 198px;
  width: 198px;
  max-width: 198px;
  height: 198px;
  margin-right: 40px;

  ${media.tablet`
    min-width: 258px;
    width: 258px;
    max-width: 258px;
    height: 258px;
    margin-right: 50px;
  `};
`;

const ImageSlideLeft = styled(ImageWrapper)`
  animation: slideLeft 30s linear infinite;

  @keyframes slideLeft {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-1700px, 0);
    }
  }
`;

const ImageSlideRight = styled(ImageWrapper)`
  animation: slideRight 30s linear infinite;

  @keyframes slideRight {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(1700px, 0);
    }
  }
`;

const Image = styled.img`
  width: 198px;
  height: 198px;
  object-fit: cover;

  ${media.tablet`
    width: 258px;
    height: 258px;
  `};
`;

const InstagramFeed = ({ title }) => {
  const { isMobile } = useMobile();
  const [topPosts, setTopPosts] = useState([]);
  const [bottomPosts, setBottomPosts] = useState([]);
  const beholdId = process.env.REACT_APP_BEHOLD_ID
    ? process.env.REACT_APP_BEHOLD_ID
    : publicRuntimeConfig.BEHOLD_ID;

  const fetchPosts = async () => {
    if (!beholdId) return;

    const response = await fetch(`https://feeds.behold.so/${beholdId}`);

    if (!response.ok) {
      console.error('Cannot find IG posts');
      return;
    }

    const data = await response.json();

    data.forEach((photo, index) => {
      index % 2 === 0
        ? setTopPosts(prev => [...prev, photo])
        : setBottomPosts(prev => [...prev, photo]);
    });
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  if (!title) return null;

  return (
    <Wrapper>
      <Container>
        <Title
          color={theme.color.brand.primary}
          marginBottom={isMobile ? 79 : 58}
        >
          {title}
        </Title>
      </Container>
      <StyledContainer marginLeft={-100}>
        <ImagesWrapper>
          {[...topPosts, ...topPosts].map((photo, index) => (
            <ImageSlideLeft
              href={photo.permalink}
              target="_blank"
              key={`instagram-feed-top-${index}`}
            >
              <Image src={photo?.thumbnailUrl || photo?.mediaUrl} />
            </ImageSlideLeft>
          ))}
        </ImagesWrapper>
      </StyledContainer>
      <StyledContainer marginLeft={-25}>
        <ImagesWrapper reverse>
          {[...bottomPosts, ...bottomPosts].reverse().map((photo, index) => (
            <ImageSlideRight
              href={photo.permalink}
              target="_blank"
              key={`instagram-feed-bottom-${index}`}
            >
              <Image src={photo?.thumbnailUrl || photo?.mediaUrl} />
            </ImageSlideRight>
          ))}
        </ImagesWrapper>
      </StyledContainer>
    </Wrapper>
  );
};

InstagramFeed.propTypes = {
  title: PropTypes.string
};

export default InstagramFeed;

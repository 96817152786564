import styled from 'styled-components';
import { media } from '../../styles/media';

export const Title = styled.h2`
  text-align: center;
  font-family: ${props => props.theme.font.brand.secondary};
  font-size: ${({ theme }) => theme.font.homepage.sectionTitle.mobileFontSize};
  line-height: ${({ theme }) =>
    theme.font.homepage.sectionTitle.mobileLineHeight};
  font-weight: ${({ theme }) => theme.font.fontWeight};
  color: ${({ theme, color }) => color || theme.color.brand.white};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;

  ${media.tablet`
    font-size: ${({ theme }) => theme.font.homepage.sectionTitle.fontSize};
    line-height: ${({ theme }) => theme.font.homepage.sectionTitle.lineHeight};
  `};
`;

import { translate, loadNamespaces } from 'react-i18next';
import i18n from '../utils/I18N/index';
const namespaces = ['common'];
export default () => ComposedComponent => {
  const Extended = translate(namespaces, { i18n, wait: process.browser })(
    ComposedComponent
  );

  Extended.getInitialProps = async ctx => {
    const composedInitialProps = ComposedComponent.getInitialProps
      ? await ComposedComponent.getInitialProps(ctx)
      : {};

    const i18nInitialProps = ctx.req
      ? i18n.getInitialProps(ctx.req, namespaces)
      : await loadNamespaces({
          components: [{ props: { namespaces } }],
          i18n
        });

    return {
      ...composedInitialProps,
      ...i18nInitialProps
    };
  };

  return Extended;
};
